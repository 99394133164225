import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const CheckboxField = ({
  fieldName,
  label,
  value,
  errors,
  onFieldChange,
  inline = false,
  inputClassName,
}) => {
  const FormControl = (
    <div key={fieldName} className={inputClassName}>
      <Form.Check
        type="checkbox"
        id={fieldName}
        label={label}
        defaultChecked={value}
        onChange={(e) => {
          onFieldChange(fieldName, e.target.checked);
        }}
      />
    </div>
  );

  const ErrorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (inline) {
    return (
      <Form.Group as={Row} className="mb-3" controlId={fieldName}>
        <Col sm={{ span: 8, offset: 4 }}>
          {FormControl}
          {ErrorFeedback}
        </Col>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className="mb-3" controlId={fieldName}>
        {FormControl}
        {ErrorFeedback}
      </Form.Group>
    );
  }
};

export default CheckboxField;
