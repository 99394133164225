import React from "react";

import {
  TextField,
  DateField,
  EnumRadioField,
  CheckboxField,
} from "components/helpers/fields";
import ReadOnlyField from "./ReadOnlyField";

const Field = ({
  editable,
  displayItem,
  prospectFormData,
  subformIdentifier,
  rowIndex,
  displayContext,
  onFieldChange,
}) => {
  if (!displayItem.visible(prospectFormData, rowIndex)) return null;

  const field = displayItem.getFieldStructure();
  const fieldIdentifier = field.identifier;

  const fieldData = field.getDataFrom(prospectFormData.formData, rowIndex);
  const value = fieldData.value;
  const errors = fieldData.errors;

  if (editable !== true) {
    return (
      <ReadOnlyField
        field={field}
        fieldContent={prospectFormData.getContentFor(
          fieldIdentifier,
          subformIdentifier,
          rowIndex
        )}
        displayContext={displayContext}
      />
    );
  }

  switch (displayItem.widgetType) {
    case "text": {
      return (
        <TextField
          fieldName={field.identifier}
          label={field.label}
          inline={displayContext === "subform" ? false : true}
          inputClassName={displayContext === "subform" ? "" : "small-field"}
          labelClassName={displayContext === "subform" ? "sr-only" : ""}
          value={value}
          errors={errors}
          onFieldChange={onFieldChange}
        />
      );
    }

    case "date": {
      return (
        <DateField
          fieldName={field.identifier}
          label={field.label}
          value={value}
          errors={errors}
          inline={displayContext === "subform" ? false : true}
          inputClassName={
            displayContext === "subform" ? "min-field" : "small-field"
          }
          labelClassName={displayContext === "subform" ? "sr-only" : ""}
          onFieldChange={onFieldChange}
        />
      );
    }

    case "enumRadio": {
      const lookup = prospectFormData.lookupStructure.find(
        (lookup) => lookup.identifier === field.lookup
      );
      return (
        <EnumRadioField
          fieldName={field.identifier}
          label={field.label}
          options={lookup.mapping}
          value={Number.parseInt(value)}
          errors={errors}
          suffix={rowIndex}
          inline={displayContext === "subform" ? false : true}
          labelClassName={displayContext === "subform" ? "sr-only" : ""}
          onFieldChange={onFieldChange}
        />
      );
    }

    case "checkbox": {
      return (
        <CheckboxField
          fieldName={field.identifier}
          label={field.label}
          value={value}
          errors={errors}
          onFieldChange={onFieldChange}
          inline={displayContext === "subform" ? false : true}
        />
      );
    }
  }
};

export default Field;
