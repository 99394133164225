import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/management_report";
import { localizeDate } from "services/date_helper";

import { Form, Table, Button, ButtonGroup } from "react-bootstrap";

const ResultsTable = ({
  results,
  shown,
  selectedRecords,
  availableStatuses,
  selectAll,
  clearSelection,
  handleRecordCheckToggle,
}) => {
  const recordSelected = (record) => {
    return selectedRecords.includes(record);
  };

  const displayStatus = (result) => {
    const status = availableStatuses.find(
      (status) => status.value == result.status
    );
    if (status.htmlDisplay) {
      return status.htmlDisplay;
    } else {
      return status.label;
    }
  };

  return (
    <>
      {shown && (
        <>
          <div className="mb-2">
            <ButtonGroup>
              <Button variant="outline-secondary" onClick={clearSelection}>
                Clear selection
              </Button>
              <Button variant="outline-secondary" onClick={selectAll}>
                Select all
              </Button>
            </ButtonGroup>
          </div>
          <Table>
            <thead className="thead-light">
              <tr>
                <th></th>
                <th className="text-nowrap">Study ID</th>
                <th>Questionnaire</th>
                <th>Timepoint</th>
                <th>Status</th>
                <th>Due date</th>
                <th>Planned method</th>
                <th>Contact details</th>
              </tr>
            </thead>
            <tbody className="bg-light">
              {results.map((result, index) => (
                <tr
                  key={index}
                  id={`${result.recordIdentifier}-row`}
                  data-testid={`row-${result.recordIdentifier}`}
                >
                  <td>
                    <Form.Group controlId={`select-${result.recordIdentifier}`}>
                      <Form.Check
                        type="checkbox"
                        checked={recordSelected(result)}
                        onChange={(e) =>
                          handleRecordCheckToggle(e.target.checked, result)
                        }
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <a
                      href={result.linkToQuestionnaireManagementLog}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="individual-identifier">
                        {result.individualIdentifier}
                      </span>
                    </a>
                    <p className="site text-muted">{result.individualSite}</p>
                  </td>
                  <td className="questionnaire">{result.questionnaire}</td>
                  <td className="timepoint">{result.timepoint}</td>
                  <td
                    className="status"
                    dangerouslySetInnerHTML={{ __html: displayStatus(result) }}
                  />
                  <td>
                    <p className="mb-3">{localizeDate(result.dueDate)}</p>
                    <ul className="list-unstyled text-nowrap">
                      {result.previousDates.map((previousDate, index) => (
                        <li key={index} className="text-muted small">
                          <i title={previousDate.title}>{previousDate.text}</i>
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="planned-methods">
                    {result.validPlannedMethods.length === 0 &&
                      !["reminder_sent", "non_responder"].includes(
                        result.status
                      ) && <p className="text-danger">Missing</p>}
                    {result.validPlannedMethods.map((plannedMethod, index) => (
                      <p key={`plannedMethod-${index}`} className="mb-0">
                        {plannedMethod}
                      </p>
                    ))}
                  </td>
                  <td className="contact-details">
                    {result.contactDetails.map((line, index) => (
                      <p key={`contact-detail-${index}`} className="mb-0">
                        {line}
                      </p>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.managementReport;

  return {
    results: relevantState.results,
    shown: relevantState.resultsShown,
    selectedRecords: relevantState.selectedRecords,
    availableStatuses: relevantState.availableStatuses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectAll: actions.selectAllRecords,
      clearSelection: actions.clearRecordSelection,
      handleRecordCheckToggle: actions.toggleRecordSelection,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);
