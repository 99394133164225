import _ from "lodash";
import React from "react";
import ReactSelect from "react-select";

const Select = (props) => {
  const defaultPadding = {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "6px",
    paddingBottom: "6px",
  };
  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    group: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    groupHeading: (provided) => ({
      ...provided,
      ...defaultPadding,
      marginBottom: 0,
      textTransform: "none",
      backgroundColor: "#e2f1fb",
      color: "inherit",
      fontSize: "inherit",
      fontWeight: "bold",
    }),
    option: (provided, state) => ({
      ...provided,
      ...defaultPadding,
      cursor: "pointer",
      fontSize: "inherit",
      fontWeight: state.data.value == "new_lookup" ? "bold" : "",
    }),
  };
  const selectProps = _.merge({ styles: customStyles }, props);

  return <ReactSelect {...selectProps} />;
};

export default Select;
