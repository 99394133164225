import { FieldStructure, SubformStructure } from ".";
import { loadDisplayItems } from "services/display";

class FormStructure {
  constructor(json) {
    this.name = json.name;
    this.display = json.display;
    this.displayItems = loadDisplayItems(json.display, this);
    this.fields = json.fields.map((field) => {
      return new FieldStructure(field, this);
    });
    this.subforms = json.subforms.map((subform) => {
      return new SubformStructure(subform, this);
    });
  }

  getFieldFromIdentifier = (identifier) => {
    return this.fields.find((field) => field.identifier === identifier);
  };

  getSubformFromIdentifier = (identifier) => {
    return this.subforms.find((subform) => subform.identifier === identifier);
  };

  getFieldDataFor(formData, fieldIdentifier, rowIndex = undefined) {
    return formData[fieldIdentifier];
  }
}

export default FormStructure;
