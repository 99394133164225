import React from "react";

import { Card } from "react-bootstrap";

const Section = ({ item, prospectFormData, children }) => {
  if (!item.visible(prospectFormData)) return null;

  return (
    <Card bg="light" className="mb-4">
      <Card.Body>
        {item.title && <Card.Title>{item.title}</Card.Title>}
        {children}
      </Card.Body>
    </Card>
  );
};

export default Section;
