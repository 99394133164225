import React from "react";

import { Card, Row, Col } from "react-bootstrap";
import { SelectField } from "components/helpers/fields";
import { CSVDownloadButton } from "components/helpers";

const ReportFilter = ({
  studyId,
  sites,
  selectedSites,
  roles,
  selectedRoles,
  roleTypes,
  selectedRoleType,
  updateSiteSelect,
  updateRoleSelect,
  updateRoleTypeSelect,
  csvData,
}) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="mb-0">Filter</Card.Title>
      </Card.Header>
      <Card.Body className="pb-2">
        <Row>
          <Col md={6}>
            <SelectField
              fieldName="selectedSites"
              label="Site"
              placeholder="Select sites..."
              options={sites}
              value={selectedSites}
              onFieldChange={updateSiteSelect}
              isMulti={true}
              isClearable={true}
              required={true}
              errors={[]}
              selectAllHandler={() => {
                updateSiteSelect(
                  "selectedElements",
                  sites.map((element) => element.value)
                );
              }}
            />
          </Col>
          <Col md={6}>
            <SelectField
              fieldName="selectedRoles"
              label="Role"
              placeholder="Select roles..."
              options={roles}
              value={selectedRoles}
              onFieldChange={updateRoleSelect}
              isMulti={true}
              isClearable={true}
              required={true}
              errors={[]}
              selectAllHandler={() => {
                updateRoleSelect(
                  "selectedRoles",
                  roles.map((element) => element.value)
                );
              }}
            />
          </Col>
          <Col md={6}>
            <SelectField
              fieldName="selectedRoleType"
              label="Role Type"
              placeholder="Select role type..."
              options={roleTypes}
              value={selectedRoleType}
              onFieldChange={updateRoleTypeSelect}
              isMulti={false}
              isClearable={true}
              required={true}
              errors={[]}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex gap-2 justify-content-end">
          <CSVDownloadButton
            headers={[
              "Name",
              "Username",
              "Role",
              "Role Type",
              "Site",
              "Last Login",
            ]}
            data={csvData}
            filename={`${studyId}_role_assignments_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results as CSV
          </CSVDownloadButton>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ReportFilter;
