import React from "react";

import Flatpickr from "react-flatpickr";

const DatePicker = ({
  fieldIdentifier,
  value,
  errors,
  onFieldChange,
  inputClassName,
  controlId,
}) => {
  const options = {
    dateFormat: "d/m/Y",
    allowInput: true,
  };

  let classNames = [];
  if (errors.length > 0) {
    classNames.push("is-invalid");
  }
  if (inputClassName) {
    classNames.push(inputClassName);
  }

  return (
    <Flatpickr
      id={controlId || fieldIdentifier}
      className={classNames.join(" ")}
      options={options}
      value={value}
      onChange={(date) => {
        onFieldChange(fieldIdentifier, date.length > 0 ? date[0] : null);
      }}
    />
  );
};

export default DatePicker;
