import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/management_report";

import { Card, Row, Col, Form, Alert, Tab, Nav } from "react-bootstrap";
import { ButtonWithSpinner } from "components/helpers";
import {
  TextField,
  DateField,
  SelectField,
  EnumRadioField,
} from "components/helpers/fields";

const BulkContactForm = ({
  saving,
  showBulkContactForm,
  availableMethods,
  contactType,
  contactDate,
  reminderYn,
  noReminderRsn,
  contactMethod,
  noCompRsn,
  baseErrors,
  selectedQuestionnaireInstanceErrors,
  selectedQuestionnaireInstances,
  site,
  timepoint,
  questionnaire,
  status,
  plannedMethod,
  updateBulkContactValue,
  runBulkContactUpdate,
  studyId,
}) => {
  const handleFormSubmission = (e) => {
    e.preventDefault();
    runBulkContactUpdate(
      studyId,
      selectedQuestionnaireInstances,
      contactType.value,
      contactDate.value,
      contactMethod.value,
      reminderYn.value,
      noReminderRsn.value,
      noCompRsn.value,
      site,
      timepoint,
      questionnaire,
      status,
      plannedMethod
    );
  };

  const SubmitButton = (
    <Row>
      <Col className="d-flex justify-content-end">
        <ButtonWithSpinner
          saving={saving}
          disabled={false}
          type="submit"
          buttonText="Update"
          savingText="Updating..."
        />
      </Col>
    </Row>
  );

  return (
    <>
      {showBulkContactForm && (
        <>
          <Card className="mb-4 bulk-contact-form">
            <Tab.Container
              defaultActiveKey="send-initial"
              id="uncontrolled-tab-example"
              onSelect={(key) => updateBulkContactValue("contactType", key)}
            >
              <Card.Header>
                <Card.Title>Update Questionnaire Management Logs</Card.Title>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="initial">
                      Send initial questionnaire
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="reminder">Send reminder</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="notCompleted">
                      Mark as not completed
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                {baseErrors.length > 0 && (
                  <Alert variant="danger">{baseErrors.join(", ")}</Alert>
                )}
                {selectedQuestionnaireInstanceErrors.length > 0 && (
                  <Alert variant="danger">
                    <p>
                      All selected records have not been updated due to the
                      following errors:
                    </p>
                    <ul>
                      {selectedQuestionnaireInstanceErrors.map(
                        (error, index) => {
                          return (
                            <li key={`quesitonnaire-instance-error-${index}`}>
                              {error}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Alert>
                )}
                <Form onSubmit={handleFormSubmission}>
                  <Tab.Content>
                    <Tab.Pane eventKey="initial">
                      <DateField
                        fieldName="contactDate"
                        label="Sent Date"
                        inputClassName="small-field"
                        value={contactDate.value}
                        errors={contactDate.errors}
                        onFieldChange={updateBulkContactValue}
                        inline={true}
                      />
                      <SelectField
                        fieldName="contactMethod"
                        label="Sent Method"
                        inputClassName="contact-method"
                        options={availableMethods}
                        value={contactMethod.value}
                        onFieldChange={updateBulkContactValue}
                        inline={true}
                        isMulti={false}
                        required={true}
                        errors={contactMethod.errors}
                      />
                      {SubmitButton}
                    </Tab.Pane>
                    <Tab.Pane eventKey="reminder">
                      <DateField
                        fieldName="contactDate"
                        controlId="reminderDate"
                        label="Reminder Date"
                        inputClassName="small-field"
                        value={contactDate.value}
                        errors={contactDate.errors}
                        onFieldChange={updateBulkContactValue}
                        inline={true}
                      />
                      <SelectField
                        fieldName="contactMethod"
                        label="Reminder Method"
                        inputClassName="contact-method"
                        inline={true}
                        options={availableMethods}
                        value={contactMethod.value}
                        onFieldChange={updateBulkContactValue}
                        isMulti={false}
                        required={true}
                        errors={contactMethod.errors}
                      />
                      <EnumRadioField
                        fieldName="reminderYn"
                        label="Reminder successful?"
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                        value={reminderYn.value}
                        errors={reminderYn.errors}
                        onFieldChange={updateBulkContactValue}
                        inline={true}
                      />
                      {reminderYn.value === 0 && (
                        <TextField
                          fieldName="noReminderRsn"
                          label="Reason reminder unsuccessful"
                          inline={true}
                          value={noReminderRsn.value}
                          errors={noReminderRsn.errors}
                          onFieldChange={updateBulkContactValue}
                        />
                      )}
                      {SubmitButton}
                    </Tab.Pane>
                    <Tab.Pane eventKey="notCompleted">
                      <TextField
                        fieldName="noCompRsn"
                        label="Reason questionnaire not completed"
                        inline={true}
                        value={noCompRsn.value}
                        errors={noCompRsn.errors}
                        onFieldChange={updateBulkContactValue}
                      />
                      {SubmitButton}
                    </Tab.Pane>
                  </Tab.Content>
                </Form>
              </Card.Body>
            </Tab.Container>
          </Card>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.managementReport;

  return {
    saving: relevantState.saving,
    showBulkContactForm: relevantState.bulkContactForm.shown,
    availableMethods: relevantState.availablePlannedMethods,
    contactType: relevantState.bulkContactForm.contactType,
    contactDate: relevantState.bulkContactForm.contactDate,
    contactMethod: relevantState.bulkContactForm.contactMethod,
    noCompRsn: relevantState.bulkContactForm.noCompRsn,
    reminderYn: relevantState.bulkContactForm.reminderYn,
    noReminderRsn: relevantState.bulkContactForm.noReminderRsn,
    baseErrors: relevantState.bulkContactForm.baseErrors,
    selectedQuestionnaireInstanceErrors:
      relevantState.bulkContactForm.selectedQuestionnaireInstanceErrors,
    studyId: relevantState.studyId,
    selectedQuestionnaireInstances: relevantState.selectedRecords,
    site: relevantState.site,
    timepoint: relevantState.timepoint,
    questionnaire: relevantState.questionnaire,
    status: relevantState.status,
    plannedMethod: relevantState.plannedMethod,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateBulkContactValue: actions.updateBulkContactValue,
      runBulkContactUpdate: actions.runBulkContactUpdate,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkContactForm);
