export const localizeDate = (date) => {
  if (date === "") {
    return "";
  } else if (date instanceof Date) {
    return date.toLocaleDateString("en-gb");
  } else {
    return new Date(date).toLocaleDateString("en-gb");
  }
};

export const orderDates = (a, b) => {
  if (a === "" && b === "") {
    return 0;
  } else if (a === "") {
    return 1;
  } else if (b === "") {
    return -1;
  }

  const date1 = Date.parse(a);
  const date2 = Date.parse(b);

  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else {
    return 0;
  }
};
