import React from "react";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  FreezeAndLockStatesReportReducer,
  freezeAndLockStatesReportDefaultState,
} from "reducers/FreezeAndLockStatesReportReducer.reducer";

import {
  ReportFilter,
  RecordsTable,
  TimeoutWarning,
} from "components/freeze_and_lock_states_report_page";
import FreezeAndLockStatesReportMiddleware from "middleware/FreezeAndLockStatesReportMiddleware.middleware";

const FreezeAndLockStatesReportPage = ({
  studyId,
  studyManagementUrl,
  individualsUrlPrefix,
  availableElements,
  availableStatuses,
  availableDiscrepancies,
  availableSites,
  availableEvents,
  availableForms,
  formBindings,
}) => {
  const store = createStore(
    combineReducers({
      freezeAndLockStatesReport: FreezeAndLockStatesReportReducer,
    }),
    {
      freezeAndLockStatesReport: {
        ...freezeAndLockStatesReportDefaultState,
        studyId: studyId,
        studyManagementUrl: studyManagementUrl,
        individualsUrlPrefix: individualsUrlPrefix,
        availableElements: availableElements,
        availableStatuses: availableStatuses,
        availableDiscrepancies: availableDiscrepancies,
        availableSites: availableSites,
        availableEvents: availableEvents,
        allAvailableForms: availableForms,
        availableForms: availableForms,
        formBindings: formBindings,
      },
    },
    applyMiddleware(FreezeAndLockStatesReportMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <TimeoutWarning />
        <ReportFilter />
        <RecordsTable />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default FreezeAndLockStatesReportPage;
