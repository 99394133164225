import React from "react";
import _ from "lodash";

import { Row, Col } from "react-bootstrap";

const ReadOnlyField = ({ displayContext, field, fieldContent }) => {
  const hasFieldContent = !(
    _.isUndefined(fieldContent) ||
    _.isNull(fieldContent) ||
    fieldContent === ""
  );
  const displayedFieldContent = hasFieldContent ? fieldContent : "[left blank]";

  switch (displayContext) {
    case "subform": {
      return (
        <span className={hasFieldContent ? "" : "text-muted"}>
          {displayedFieldContent}
        </span>
      );
    }
    case "form": {
      return (
        <Row data-testid={`${field.identifier}`}>
          <Col as="dt" sm="4" className="mb-3">
            {field.label}
          </Col>
          <Col as="dd" sm="8" className="mb-0">
            <span className={hasFieldContent ? "" : "text-muted"}>
              {displayedFieldContent}
            </span>
          </Col>
        </Row>
      );
    }
  }
};

export default ReadOnlyField;
