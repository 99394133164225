class FieldDisplay {
  constructor(json, parentStructure) {
    this.parentStructure = parentStructure;
    this.type = json.type;
    this.widgetType = json.widgetType;
    this.fieldIdentifier = json.fieldIdentifier;
  }

  getFieldStructure() {
    return this.parentStructure.getFieldFromIdentifier(this.fieldIdentifier);
  }

  visible(prospectFormData, rowIndex = undefined) {
    const field = this.getFieldStructure();
    return (
      field.getDataFrom(prospectFormData.formData, rowIndex).hidden === false
    );
  }
}

export default FieldDisplay;
