class FieldStructure {
  constructor(json, parent) {
    this.parent = parent; // The form or subform that this field belongs to
    this.identifier = json.identifier;
    this.label = json.label;
    this.type = json.type;
    this.lookup = json.lookup;
    this.dependencies = json.dependencies;
  }

  getDataFrom(formData, rowIndex) {
    return this.parent.getFieldDataFor(formData, this.identifier, rowIndex);
  }
}

export default FieldStructure;
