import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "siteRoles",
    "studyRoles",
    "siteRoleInput",
    "studyRoleInput",
  ];
  static values = {
    type: String,
  };

  connect() {
    this.updateRoleVisibility();
  }

  changeType(event) {
    this.typeValue = event.target.value;
    this.updateRoleVisibility();
  }

  updateRoleVisibility() {
    if (this.typeValue === "site") {
      this.hideAndDisable(this.studyRolesTarget, this.studyRoleInputTargets);
      this.showAndEnable(this.siteRolesTarget, this.siteRoleInputTargets);
    } else {
      this.showAndEnable(this.studyRolesTarget, this.studyRoleInputTargets);
      this.hideAndDisable(this.siteRolesTarget, this.siteRoleInputTargets);
    }
  }

  hideAndDisable(target, inputs) {
    target.classList.add("d-none");
    inputs.forEach((input) => {
      input.setAttribute("disabled", "");
    });
  }

  showAndEnable(target, inputs) {
    target.classList.remove("d-none");
    inputs.forEach((input) => {
      input.removeAttribute("disabled");
    });
  }
}
