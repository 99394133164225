class SubformDisplay {
  constructor(json, parentStructure) {
    this.parentStructure = parentStructure;
    this.type = json.type;
    this.subformIdentifier = json.subformIdentifier;
  }

  getSubformStructure() {
    return this.parentStructure.getSubformFromIdentifier(
      this.subformIdentifier
    );
  }

  visible(prospectFormData) {
    const subformStructure = this.getSubformStructure();
    return prospectFormData.isSubformVisible(subformStructure);
  }
}

export default SubformDisplay;
