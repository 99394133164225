import React from "react";

import Section from "./Section";
import Field from "./Field";
import Subform from "./Subform";
import InlineFields from "./InlineFields";

const DisplayItem = ({
  editable,
  item,
  prospectFormData,
  onFieldChange,
  onSubformFieldChange,
  addSubformRow,
  removeSubformRow,
}) => {
  switch (item.type) {
    case "section": {
      return (
        <Section item={item} prospectFormData={prospectFormData}>
          {item.children.map((item, index) => {
            return (
              <DisplayItem
                key={index}
                editable={editable}
                item={item}
                prospectFormData={prospectFormData}
                onFieldChange={onFieldChange}
                onSubformFieldChange={onSubformFieldChange}
                addSubformRow={addSubformRow}
                removeSubformRow={removeSubformRow}
              />
            );
          })}
        </Section>
      );
    }

    case "field": {
      return (
        <Field
          editable={editable}
          displayItem={item}
          prospectFormData={prospectFormData}
          displayContext="form"
          onFieldChange={onFieldChange}
        />
      );
    }

    case "subform": {
      return (
        <Subform
          editable={editable}
          displayItem={item}
          prospectFormData={prospectFormData}
          onSubformFieldChange={onSubformFieldChange}
          addSubformRow={addSubformRow}
          removeSubformRow={removeSubformRow}
        />
      );
    }

    case "inlineFields": {
      return (
        <InlineFields
          displayItem={item}
          editable={editable}
          prospectFormData={prospectFormData}
          onFieldChange={onFieldChange}
        />
      );
    }

    default: {
      throw `Unable to render item of type "${item.type}"`;
    }
  }
};

export default DisplayItem;
