class DependencyChecker {
  constructor(prospectFormData) {
    this.prospectFormData = prospectFormData;
  }

  dependenciesMet = (
    dependencies,
    subformIdentifier = undefined,
    rowIndex = 0
  ) => {
    if (!dependencies) return true;

    // TODO: support other types of checks
    // (currently checks *ALL* dependencies match)
    return dependencies.every((dependency) => {
      const fieldValue = this.getDependencyValueFor(
        dependency.field,
        subformIdentifier,
        rowIndex
      );
      switch (dependency.comparison) {
        case "==": {
          return dependency.value === fieldValue;
        }
        case "!=": {
          console.log(dependency.value, "!=", fieldValue);
          return dependency.value !== fieldValue;
        }
      }
    });
  };

  // Private

  getDependencyValueFor(
    fieldIdentifier,
    subformIdentifier = undefined,
    rowIndex = 0
  ) {
    const fieldStructure = this.prospectFormData.getFieldFor(
      fieldIdentifier,
      subformIdentifier
    );
    if (
      !this.prospectFormData.isFieldVisible(
        fieldStructure,
        subformIdentifier,
        rowIndex
      )
    )
      return null;

    const fieldValue = this.prospectFormData.getValueFor(
      fieldIdentifier,
      subformIdentifier,
      rowIndex
    );

    switch (fieldStructure.type) {
      case "enum":
        const selectedLookup = this.prospectFormData.lookupStructure.find(
          (lookup) => lookup.identifier == fieldStructure.lookup
        );
        const selectedMapping = selectedLookup
          ? selectedLookup.mapping.find(
              (mapping) => mapping.value == fieldValue
            )
          : undefined;
        if (selectedMapping) return selectedMapping.label;
        break;
      case "date":
        return fieldValue instanceof Date
          ? fieldValue.toLocaleDateString("en-gb")
          : fieldValue;
      default:
        return fieldValue;
    }
  }
}

export default DependencyChecker;
