import jQuery from "jquery";
import { Modal } from "bootstrap";

export default (function ($) {
  const MODAL_WINDOW_ID = "modalWindow";
  const MODAL_WINDOW_FINDER = `#${MODAL_WINDOW_ID}`;

  const getModalElement = () => document.getElementById(MODAL_WINDOW_ID);
  const getModalInstance = () => Modal.getOrCreateInstance(MODAL_WINDOW_FINDER);

  const openModal = function (data) {
    // Find or create a modal window
    const $modal =
      $(MODAL_WINDOW_FINDER).length > 0
        ? $(MODAL_WINDOW_FINDER)
        : $(
            `<div id="${MODAL_WINDOW_ID}" class="modal fade" tabindex="-1" role="dialog"></div>`
          );

    // Load the response into the modal and set up some sensible parameters
    $modal.html(data);
    $(".modal-title", $modal).attr("id", "modalWindowTitle");
    $modal.attr("aria-labelledby", "modalWindowTitle");
    $(".modal-dialog", $modal).attr("role", "document");

    // Determine if the modal has already been shown
    // and add the updated modal to the body
    const alreadyShown = $modal.hasClass("show");
    $("body").append($modal);

    // Add the relevant listeners
    const modalElement = getModalElement();
    modalElement.addEventListener("shown.bs.modal", function (e) {
      $(`${MODAL_WINDOW_FINDER} [autofocus]`).first().focus();
      $(document).trigger("ajax-modal-shown");
    });
    modalElement.addEventListener("hidden.bs.modal", function (e) {
      if (e.target === this) {
        $(this).remove();
      }
    });

    // Show the modal (via Bootstrap)
    getModalInstance().show();

    $(document).trigger("ajax-modal-show");
    if (alreadyShown) {
      $(document).trigger("ajax-modal-shown");
    }
  };

  const loadModal = function (url, params) {
    $.get(url, params, openModal);
  };

  $(function () {
    // Allow the modal window to be hidden from plain JavaScript
    window.hideAjaxModal = () => {
      if (getModalElement()) getModalInstance().hide();
    };

    // Listen for the relevant clicks to load the modal
    $(document.body).on(
      "click",
      'a.ajax-modal, a[data-toggle="ajax-modal"]',
      function (e) {
        e.preventDefault();
        loadModal($(this).attr("href"), $(this).data("params"));
      }
    );
  });
})(jQuery);
