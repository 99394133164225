import React from "react";
import { connect } from "react-redux";

import { Table } from "react-bootstrap";
import { TabLink } from "components/helpers";

const ResultsTable = ({
  shown,
  records,
  availableStatuses,
  studyManagementUrl,
  individualsUrlPrefix,
}) => {
  const statusFor = (record) =>
    availableStatuses.filter(
      (option) => record.currentStatus === option.value
    )[0].label;

  const tabLinkHrefFor = (record) => {
    if (record.elementType == "Study") {
      return studyManagementUrl;
    } else if (record.elementType == "Site") {
      return `${studyManagementUrl}#sites`;
    } else if (record.elementType == "Individual") {
      return `${individualsUrlPrefix}${record.individualIdentifier}`;
    } else if (record.elementType == "Event" || record.elementType == "Form") {
      return `${individualsUrlPrefix}${record.individualIdentifier}#${record.linkAnchor}`;
    }
  };

  if (!shown) {
    return <></>;
  }

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th className="col-sm-5">Identifier</th>
          <th className="col-sm-2">Element</th>
          <th className="col-sm-2">Current status</th>
          <th className="col-sm-3">
            Discrepancies associated with target element?
          </th>
        </tr>
      </thead>
      <tbody>
        {records.length === 0 && (
          <tr>
            <td colSpan={4}>
              <p className="text-muted text-center mb-0">No records found</p>
            </td>
          </tr>
        )}
        {records.map((record, i) => {
          return (
            <tr key={`record-${i}`}>
              <td>
                <TabLink href={tabLinkHrefFor(record)}>
                  {record.identifier}
                </TabLink>
              </td>
              <td>{record.elementType}</td>
              <td>{statusFor(record)}</td>
              <td>{record.discrepancies}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.freezeAndLockStatesReport;

  return {
    shown: relevantState.showRecords,
    records: relevantState.records,
    availableStatuses: relevantState.availableStatuses,
    studyManagementUrl: relevantState.studyManagementUrl,
    individualsUrlPrefix: relevantState.individualsUrlPrefix,
  };
};

export default connect(mapStateToProps)(ResultsTable);
