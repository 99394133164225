import { FieldStructure } from ".";
import { loadDisplayItems } from "services/display";

class SubformStructure {
  constructor(json, parentForm) {
    this.parentForm = parentForm;
    this.identifier = json.identifier;
    this.name = json.name;
    this.display = json.display;
    this.displayItems = loadDisplayItems(json.display, this);
    this.fields = json.fields.map((field) => {
      return new FieldStructure(field, this);
    });
    this.dependencies = json.dependencies;
  }

  getFieldFromIdentifier(identifier) {
    return this.fields.find((field) => field.identifier === identifier);
  }

  getFieldDataFor(formData, fieldIdentifier, rowIndex) {
    return formData[this.identifier][rowIndex].subformData[fieldIdentifier];
  }
}

export default SubformStructure;
