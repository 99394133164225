import { postRequest } from "./base_requests";

const filterParams = (
  sites,
  timepoints,
  questionnaires,
  statuses,
  plannedMethods
) => {
  return {
    sites: sites,
    timepoints: timepoints,
    questionnaires: questionnaires,
    statuses: statuses,
    planned_methods: plannedMethods,
  };
};

export function runReport(
  studyId,
  sites,
  timepoints,
  questionnaires,
  statuses,
  plannedMethods
) {
  return postRequest({
    url: `/studies/${studyId}/questionnaires/management_report/run_report.json`,
    body: {
      filter: filterParams(
        sites,
        timepoints,
        questionnaires,
        statuses,
        plannedMethods
      ),
    },
  });
}

export function bulkContact(
  studyId,
  selectedQuestionnaireInstances,
  contactType,
  contactDate,
  contactMethod,
  reminderYn,
  noReminderRsn,
  noCompRsn,
  sites,
  timepoints,
  questionnaires,
  statuses,
  plannedMethods
) {
  const formattedDate =
    contactDate instanceof Date
      ? contactDate.toLocaleDateString("en-gb")
      : contactDate;

  return postRequest({
    url: `/studies/${studyId}/questionnaires/management_report/bulk_contact.json`,
    body: {
      bulk_contact: {
        selected_questionnaire_instances: selectedQuestionnaireInstances.map(
          (instance) => {
            return {
              record_identifier: instance.recordIdentifier,
              questionnaire_identifier: instance.questionnaireIdentifier,
              timepoint_identifier: instance.timepointIdentifier,
              individual_identifier: instance.individualRecordIdentifier,
            };
          }
        ),
        contact_type: contactType,
        contact_date: formattedDate,
        contact_method: contactMethod,
        reminder_yn: reminderYn,
        no_reminder_rsn: noReminderRsn,
        no_qst_comp_rsn: noCompRsn,
      },
      filter: filterParams(
        sites,
        timepoints,
        questionnaires,
        statuses,
        plannedMethods
      ),
    },
  });
}
