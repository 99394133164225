import React from "react";
import ReactSelect from "react-select";

const TabLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default TabLink;
