import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/freeze_and_lock_states_report";

import { Modal, Button } from "react-bootstrap";

const TimeoutWarning = ({
  show,
  studyId,
  filterValues,
  handleClose,
  handleAccept,
}) => {
  return (
    <Modal id="modalWindow" backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Timeout Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The options you have selected may cause the report to run slowly, and
          possibly timeout if they take longer than 5 minutes. You can increase
          the speed of the report by either removing Event and Form from the
          selected elements, or by filtering to a specific site or individual.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="outline-secondary" onClick={handleClose}>
          Go back
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleAccept(studyId, filterValues);
          }}
        >
          Run report
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.freezeAndLockStatesReport;

  return {
    show: relevantState.showTimeoutWarning,
    studyId: relevantState.studyId,
    filterValues: relevantState.filterValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleClose: actions.closeTimeoutWarning,
      handleAccept: actions.runReport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutWarning);
