import React from "react";
import { connect } from "react-redux";

import { localizeDate } from "services/date_helper";

import { CSVDownloadButton } from "components/helpers";

const CSVDownload = ({
  studyId,
  results,
  selectedRecords,
  availableStatuses,
}) => {
  const createRowFor = (result) => {
    let row = [
      [result.individualIdentifier],
      [result.individualSite],
      [result.timepoint],
      [result.questionnaire],
      [availableStatuses.find((status) => status.value == result.status).label],
      [localizeDate(result.dueDate)],
      result.validPlannedMethods,
      [result.contactFirstName],
      [result.contactLastName],
    ];

    if (result.validPlannedMethods.includes("Post")) {
      row.push([result.contactAddress1]);
      row.push([result.contactAddress2]);
      row.push([result.contactTownCity]);
      row.push([result.contactPostcode]);
    }
    return row;
  };

  const csvData = [];
  let recordsToUseForExport = results;

  if (selectedRecords.length > 0) {
    recordsToUseForExport = selectedRecords;
  }

  recordsToUseForExport.map((result) => {
    const row = createRowFor(result);
    csvData.push(row);
  });

  let qualtricsCsvData = [];
  recordsToUseForExport.map((result) => {
    qualtricsCsvData.push([
      [result.individualIdentifier],
      [result.contactFirstName],
      [result.contactLastName],
      [result.contactEmail],
      [result.timepoint],
      [result.questionnaire],
      [result.individualSite],
      [result.individualSiteLongName],
      [result.individualSiteCode],
    ]);
  });

  return (
    <>
      <CSVDownloadButton
        headers={[
          "ExternalDataReference",
          "FirstName",
          "LastName",
          "PrimaryEmail",
          "Timepoint",
          "Questionnaire",
          "Site",
          "SiteName",
          "SiteCode",
        ]}
        data={qualtricsCsvData}
        filename={`${studyId}_qualtrics_questionnaire_report`}
        addTimeStamp={true}
        active={results.length > 0}
      >
        {selectedRecords.length > 0 &&
          selectedRecords.length !== results.length &&
          "Download selected in qualtrics format"}
        {(selectedRecords.length === 0 ||
          selectedRecords.length === results.length) &&
          "Download all in qualtrics format"}
      </CSVDownloadButton>
      <CSVDownloadButton
        headers={[
          "Study ID",
          "Site",
          "Timepoint",
          "Questionnaire",
          "Status",
          "Due date",
          "Planned method",
          "First Name",
          "Last Name",
          "Address 1",
          "Address 2",
          "Town/City",
          "Postcode",
        ]}
        data={csvData}
        filename={`${studyId}_questionnaire_report`}
        addTimeStamp={true}
        active={results.length > 0}
      >
        {selectedRecords.length > 0 &&
          selectedRecords.length !== results.length &&
          "Download selected as CSV"}
        {(selectedRecords.length === 0 ||
          selectedRecords.length === results.length) &&
          "Download all as CSV"}
      </CSVDownloadButton>
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.managementReport;

  return {
    studyId: relevantState.studyId,
    results: relevantState.results,
    selectedRecords: relevantState.selectedRecords,
    availableStatuses: relevantState.availableStatuses,
  };
};

export default connect(mapStateToProps)(CSVDownload);
