export const ActionTypes = {
  UPDATE_FILTER_VALUE: "UPDATE_FILTER_VALUE",
  VALIDATE_FILTERS: "VALIDATE_FILTERS",
  CLOSE_TIMEOUT_WARNING: "CLOSE_TIMEOUT_WARNING",
  RUN_REPORT: "RUN_REPORT",
  RUN_REPORT_SUCCESS: "RUN_REPORT_SUCCESS",
  RUN_REPORT_FAILURE: "RUN_REPORT_FAILURE",
  RUN_REPORT_ERROR: "RUN_REPORT_ERROR",
};

const updateFilterValue = (fieldIdentifier, value) => ({
  type: ActionTypes.UPDATE_FILTER_VALUE,
  fieldIdentifier,
  value,
});

const validateFilters = () => ({
  type: ActionTypes.VALIDATE_FILTERS,
});

const closeTimeoutWarning = () => ({
  type: ActionTypes.CLOSE_TIMEOUT_WARNING,
});

const runReport = (studyId, filterValues) => ({
  type: ActionTypes.RUN_REPORT,
  studyId,
  filterValues,
});

const runReportSuccess = (responseData) => ({
  type: ActionTypes.RUN_REPORT_SUCCESS,
  responseData,
});

const runReportFailure = (responseData) => ({
  type: ActionTypes.RUN_REPORT_FAILURE,
  responseData,
});

const runReportError = (exception) => ({
  type: ActionTypes.RUN_REPORT_ERROR,
  exception,
});

export const actions = {
  updateFilterValue,
  validateFilters,
  closeTimeoutWarning,
  runReport,
  runReportSuccess,
  runReportFailure,
  runReportError,
};
