export const ActionTypes = {
  UPDATE_FILTER_VALUE: "UPDATE_FILTER_VALUE",
  DISMISS_FLASH_MESSAGE: "DISMISS_FLASH_MESSAGE",
  RUN_REPORT: "RUN_REPORT",
  RUN_REPORT_SUCCESS: "RUN_REPORT_SUCCESS",
  RUN_REPORT_FAILURE: "RUN_REPORT_FAILURE",
  RUN_REPORT_ERROR: "RUN_REPORT_ERROR",
  RUN_BULK_CONTACT_UPDATE: "RUN_BULK_CONTACT_UPDATE",
  RUN_BULK_CONTACT_UPDATE_SUCCESS: "RUN_BULK_CONTACT_UPDATE_SUCCESS",
  RUN_BULK_CONTACT_UPDATE_FAILURE: "RUN_BULK_CONTACT_UPDATE_FAILURE",
  TOGGLE_RECORD_SELECTION: "TOGGLE_RECORD_SELECTION",
  SELECT_ALL_RECORDS: "SELECT_ALL_RECORDS",
  CLEAR_RECORD_SELECTION: "CLEAR_RECORD_SELECTION",
  UPDATE_BULK_CONTACT_VALUE: "UPDATE_BULK_CONTACT_VALUE",
};

const updateFilterValue = (fieldIdentifier, value) => ({
  type: ActionTypes.UPDATE_FILTER_VALUE,
  fieldIdentifier,
  value,
});

const dismissFlashMessage = (messageIndex) => ({
  type: ActionTypes.DISMISS_FLASH_MESSAGE,
  messageIndex,
});

const runReport = (
  studyId,
  site,
  timepoint,
  questionnaire,
  status,
  plannedMethod
) => ({
  type: ActionTypes.RUN_REPORT,
  studyId,
  site,
  timepoint,
  questionnaire,
  status,
  plannedMethod,
});

const runReportSuccess = (responseData) => ({
  type: ActionTypes.RUN_REPORT_SUCCESS,
  responseData,
});

const runReportFailure = (responseData) => ({
  type: ActionTypes.RUN_REPORT_FAILURE,
  responseData,
});

const runReportError = (exception) => ({
  type: ActionTypes.RUN_REPORT_ERROR,
  exception,
});

const runBulkContactUpdate = (
  studyId,
  selectedQuestionnaireInstances,
  contactType,
  contactDate,
  contactMethod,
  reminderYn,
  noReminderRsn,
  noCompRsn,
  site,
  timepoint,
  questionnaire,
  status,
  plannedMethod
) => ({
  type: ActionTypes.RUN_BULK_CONTACT_UPDATE,
  studyId,
  selectedQuestionnaireInstances,
  contactType,
  contactDate,
  contactMethod,
  reminderYn,
  noReminderRsn,
  noCompRsn,
  site,
  timepoint,
  questionnaire,
  status,
  plannedMethod,
});

const runBulkContactUpdateSuccess = (responseData) => ({
  type: ActionTypes.RUN_BULK_CONTACT_UPDATE_SUCCESS,
  responseData,
});

const runBulkContactUpdateFailure = (responseData) => ({
  type: ActionTypes.RUN_BULK_CONTACT_UPDATE_FAILURE,
  responseData,
});

const toggleRecordSelection = (checked, record) => ({
  type: ActionTypes.TOGGLE_RECORD_SELECTION,
  record,
  checked,
});

const selectAllRecords = () => ({
  type: ActionTypes.SELECT_ALL_RECORDS,
});

const clearRecordSelection = () => ({
  type: ActionTypes.CLEAR_RECORD_SELECTION,
});

const updateBulkContactValue = (fieldIdentifier, value) => ({
  type: ActionTypes.UPDATE_BULK_CONTACT_VALUE,
  fieldIdentifier,
  value,
});

export const actions = {
  updateFilterValue,
  dismissFlashMessage,
  runReport,
  runReportSuccess,
  runReportFailure,
  runReportError,
  toggleRecordSelection,
  selectAllRecords,
  clearRecordSelection,
  updateBulkContactValue,
  runBulkContactUpdate,
  runBulkContactUpdateSuccess,
  runBulkContactUpdateFailure,
};
