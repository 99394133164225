import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import FlashMessage from "./FlashMessage";

const portalRoot = document.getElementById("flash-messages");

const FlashMessagePortal = ({ messages, dismissMessage }) => {
  const flashContainer = document.createElement("div");

  useEffect(() => {
    portalRoot.appendChild(flashContainer);
    return () => {
      portalRoot.removeChild(flashContainer);
    };
  });

  const renderMessages = () => {
    return messages.map((message, index) => {
      return (
        <FlashMessage
          key={index}
          index={index}
          message={message}
          onDismiss={dismissMessage}
        />
      );
    });
  };

  return ReactDOM.createPortal(renderMessages(), flashContainer);
};

export default FlashMessagePortal;
