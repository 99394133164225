import { processRequest, dispatchWithTimeout } from "services/base_requests";

import { actions, ActionTypes } from "actions/questionnaire_completion_log";
import {
  createCompletionLog,
  updateCompletionLog,
} from "services/completion_log_form";

export const CompletionLogFormMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-danger");

    switch (action.type) {
      case ActionTypes.NEW_COMPLETION_LOG_SUBMIT: {
        const {
          studyIdentifier,
          individualIdentifier,
          eventDefinitionIdentifier,
          questionnaireIdentifier,
          formData,
        } = action;

        const request = createCompletionLog(
          studyIdentifier,
          individualIdentifier,
          eventDefinitionIdentifier,
          questionnaireIdentifier,
          formData
        );
        const success = successHandlerFor(
          actions.newCompletionLogSubmitSuccess
        );
        const failure = failureHandlerFor(
          actions.newCompletionLogSubmitFailure
        );
        const error = failureHandlerFor(actions.newCompletionLogSubmitError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.NEW_COMPLETION_LOG_SUBMIT_SUCCESS: {
        window.location.href = action.responseData.redirect_to;
        break;
      }

      case ActionTypes.EDIT_COMPLETION_LOG_SUBMIT: {
        const {
          studyIdentifier,
          individualIdentifier,
          eventDefinitionIdentifier,
          questionnaireIdentifier,
          formData,
        } = action;

        const request = updateCompletionLog(
          studyIdentifier,
          individualIdentifier,
          eventDefinitionIdentifier,
          questionnaireIdentifier,
          formData
        );
        const success = successHandlerFor(
          actions.editCompletionLogSubmitSuccess
        );
        const failure = failureHandlerFor(
          actions.editCompletionLogSubmitFailure
        );
        const error = failureHandlerFor(actions.editCompletionLogSubmitError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.NEW_COMPLETION_LOG_SUBMIT_SUCCESS: {
        window.location.href = action.responseData.redirect_to;
        break;
      }

      case ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_SUCCESS: {
        window.location.href = action.responseData.redirect_to;
        break;
      }
    }

    return next(action);
  };
