import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/freeze_and_lock_states_report";

import { Card, Form, Row, Col } from "react-bootstrap";
import { ButtonWithSpinner, CSVDownloadButton } from "components/helpers";
import { SelectField, TextField } from "components/helpers/fields";

const ReportFilter = ({
  studyId,
  availableElements,
  availableStatuses,
  availableDiscrepancies,
  availableSites,
  availableEvents,
  availableForms,
  filterValues,
  records,
  updateFilterValue,
  validateFilters,
  runReport,
  errors,
  saving,
}) => {
  // Convert the available statuses into a map to get the human readable status name
  const statusMap = {};
  availableStatuses.map((statusElement) => {
    statusMap[statusElement.value] = statusElement.label;
  });

  const csvData = records.map((record) => {
    return [
      record.study,
      record.site,
      record.individual,
      record.event,
      record.form,
      record.elementType,
      statusMap[record.currentStatus],
      record.discrepancies,
    ];
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const includesFormsOrEvents =
          filterValues.selectedElements.includes("event") ||
          filterValues.selectedElements.includes("form");
        const includesAtLeastOneStatus = !_.isEmpty(
          filterValues.selectedStatuses
        );
        const filterByScreeningNumber = !_.isEmpty(
          filterValues.screeningNumber
        );
        const filterBySite = !_.isEmpty(filterValues.selectedSites);

        if (
          includesFormsOrEvents &&
          includesAtLeastOneStatus &&
          !(filterByScreeningNumber || filterBySite)
        ) {
          validateFilters();
        } else {
          runReport(studyId, filterValues);
        }
      }}
    >
      <Card className="mb-4">
        <Card.Header>
          <Card.Title className="mb-0">Filter</Card.Title>
        </Card.Header>
        <Card.Body className="pb-2">
          <Row>
            <Col md={6}>
              <SelectField
                fieldName="selectedElements"
                label="Element"
                placeholder="Select elements..."
                options={availableElements}
                value={filterValues.selectedElements}
                onFieldChange={updateFilterValue}
                isMulti={true}
                isClearable={true}
                required={true}
                disabled={saving}
                errors={errors.selectedElements}
                selectAllHandler={() => {
                  updateFilterValue(
                    "selectedElements",
                    availableElements.map((element) => element.value)
                  );
                }}
              />
            </Col>
            <Col md={6}>
              <SelectField
                fieldName="selectedStatuses"
                label="Status"
                placeholder="Select statuses..."
                options={availableStatuses}
                value={filterValues.selectedStatuses}
                onFieldChange={updateFilterValue}
                isMulti={true}
                isClearable={true}
                required={true}
                disabled={saving}
                errors={errors.selectedStatuses}
                selectAllHandler={() => {
                  updateFilterValue(
                    "selectedStatuses",
                    availableStatuses.map((status) => status.value)
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectField
                fieldName="selectedDiscrepancy"
                label="Active discrepancies"
                options={availableDiscrepancies}
                value={filterValues.selectedDiscrepancy}
                onFieldChange={updateFilterValue}
                required={true}
                disabled={saving}
                errors={errors.selectedDiscrepancy}
              />
            </Col>
          </Row>
          <hr className="mt-1" />
          <Row>
            <Col md={6}>
              <SelectField
                fieldName="selectedSites"
                label="Site"
                placeholder="- All sites -"
                options={availableSites}
                value={filterValues.selectedSites}
                isMulti={true}
                isClearable={true}
                onFieldChange={updateFilterValue}
                required={false}
                disabled={saving}
                errors={errors.selectedSites}
                selectAllHandler={() => {
                  updateFilterValue("selectedSites", []);
                }}
              />
            </Col>
            <Col md={6}>
              <SelectField
                fieldName="selectedEvents"
                label="Event"
                placeholder="- All events -"
                options={availableEvents}
                value={filterValues.selectedEvents}
                isMulti={true}
                isClearable={true}
                onFieldChange={updateFilterValue}
                required={false}
                disabled={saving}
                errors={errors.selectedEvents}
                selectAllHandler={() => {
                  updateFilterValue("selectedEvents", []);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectField
                fieldName="selectedForms"
                label="Form"
                placeholder="- All forms -"
                options={availableForms}
                value={filterValues.selectedForms}
                isMulti={true}
                isClearable={true}
                onFieldChange={updateFilterValue}
                required={false}
                disabled={saving}
                errors={errors.selectedForms}
                selectAllHandler={() => {
                  updateFilterValue("selectedForms", []);
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                fieldName="screeningNumber"
                label="Screening"
                value={filterValues.screeningNumber}
                disabled={saving}
                errors={errors.screeningNumber}
                onFieldChange={updateFilterValue}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex gap-2 justify-content-end">
            <CSVDownloadButton
              headers={[
                "Study",
                "Site",
                "Individual",
                "Event",
                "Form",
                "Element",
                "Current Status",
                "Discrepancies associated with target element?",
              ]}
              data={csvData}
              filename={`${studyId}_freeze_and_lock_states_report`}
              addTimeStamp={true}
              active={records.length > 0 && !saving}
            >
              Download results as CSV
            </CSVDownloadButton>
            <ButtonWithSpinner
              saving={saving}
              type="submit"
              buttonText="Run report"
              savingText="Running..."
            />
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.freezeAndLockStatesReport;

  return {
    studyId: relevantState.studyId,
    availableElements: relevantState.availableElements,
    availableStatuses: relevantState.availableStatuses,
    availableDiscrepancies: relevantState.availableDiscrepancies,
    availableSites: relevantState.availableSites,
    availableEvents: relevantState.availableEvents,
    availableForms: relevantState.availableForms,
    filterValues: relevantState.filterValues,
    records: relevantState.records,
    errors: relevantState.errors,
    saving: relevantState.saving,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFilterValue: actions.updateFilterValue,
      validateFilters: actions.validateFilters,
      runReport: actions.runReport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilter);
