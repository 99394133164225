import _ from "lodash";
import { ActionTypes } from "actions/freeze_and_lock_states_report";

const errorsDefaultState = {
  selectedElements: [],
  selectedStatuses: [],
  selectedDiscrepancy: [],
  selectedSites: [],
  selectedEvents: [],
  selectedForms: [],
  screeningNumber: [],
};

export const freezeAndLockStatesReportDefaultState = {
  studyId: undefined,
  availableElements: [],
  availableStatuses: [],
  availableDiscrepancies: [],
  availableSites: [],
  availableEvents: [],
  allAvailableForms: [],
  availableForms: [],
  formBindings: {},
  filterValues: {
    selectedElements: [],
    selectedStatuses: [],
    selectedDiscrepancy: "Any",
    selectedSites: [],
    selectedEvents: [],
    selectedForms: [],
    screeningNumber: "",
  },
  errors: errorsDefaultState,
  showTimeoutWarning: false,
  showRecords: false,
  records: [],
  saving: false,
};

const availableFormsFor = (state) => {
  const selectedEvents = state.filterValues.selectedEvents;
  if (selectedEvents.length > 0) {
    const selectedBindings = Object.entries(state.formBindings)
      .filter(([eventDefinitionId, bindings]) => {
        return selectedEvents.includes(parseInt(eventDefinitionId));
      })
      .map(([eventDefinitionId, bindings]) => bindings)
      .flat();

    return state.allAvailableForms.filter((form) =>
      selectedBindings.includes(form.value)
    );
  } else {
    return state.allAvailableForms;
  }
};

const selectedFormsFor = (state, newAvailableForms) => {
  const availableFormValues = newAvailableForms.map((form) => form.value);
  return state.filterValues.selectedForms.filter((form) => {
    return availableFormValues.includes(form);
  });
};

export const FreezeAndLockStatesReportReducer = (
  state = freezeAndLockStatesReportDefaultState,
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FILTER_VALUE: {
      let newState = {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.fieldIdentifier]: action.value,
        },
        showRecords: false,
      };

      if (action.fieldIdentifier === "selectedEvents") {
        const newAvailableForms = availableFormsFor(newState);
        const newSelectedForms = selectedFormsFor(newState, newAvailableForms);
        newState = {
          ...newState,
          availableForms: newAvailableForms,
          filterValues: {
            ...newState.filterValues,
            selectedForms: newSelectedForms,
          },
        };
      }
      return newState;
    }
    case ActionTypes.VALIDATE_FILTERS: {
      return { ...state, showTimeoutWarning: true };
    }
    case ActionTypes.CLOSE_TIMEOUT_WARNING: {
      return { ...state, showTimeoutWarning: false };
    }
    case ActionTypes.RUN_REPORT: {
      return {
        ...state,
        showTimeoutWarning: false,
        errors: errorsDefaultState,
        saving: true,
      };
    }
    case ActionTypes.RUN_REPORT_SUCCESS: {
      return {
        ...state,
        showRecords: true,
        records: action.responseData.records,
        saving: false,
      };
    }
    case ActionTypes.RUN_REPORT_FAILURE: {
      const newState = {
        ...state,
        errors: { ...errorsDefaultState, ...action.responseData.errors },
        saving: false,
      };
      return newState;
    }
    case ActionTypes.RUN_REPORT_ERROR: {
      return { ...state, saving: false };
    }
  }
  return state;
};
