import { loadDisplayItems } from ".";

class InlineFieldsDisplay {
  constructor(json, parentStructure) {
    this.type = json.type;
    this.parentStructure = parentStructure;
    this.label = json.label;
    this.leftField = loadDisplayItems([json.leftField], parentStructure)[0];
    this.rightField = loadDisplayItems([json.rightField], parentStructure)[0];
  }

  visible(prospectFormData, rowIndex = undefined) {
    return (
      this.leftField.visible(prospectFormData, rowIndex) ||
      this.rightField.visible(prospectFormData, rowIndex)
    );
  }
}

export default InlineFieldsDisplay;
