import React, { useState } from "react";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  ManagementReportReducer,
  loadReportDataFrom,
} from "reducers/managementReportReducer.reducer";
import ManagementReportMiddleware from "middleware/ManagementReportMiddleware.middleware";

import ManagementReportScreen from "components/management_report_page/ManagementReportScreen";

const ManagementReportPage = ({
  studyId,
  availableSites,
  availableStatuses,
  availableTimepoints,
  availableQuestionnaires,
}) => {
  const store = createStore(
    combineReducers({
      managementReport: ManagementReportReducer,
    }),
    {
      managementReport: loadReportDataFrom(
        studyId,
        availableSites,
        availableStatuses,
        availableQuestionnaires,
        availableTimepoints
      ),
    },
    applyMiddleware(ManagementReportMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <ManagementReportScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default ManagementReportPage;
