import React from "react";

import { Form, Card } from "react-bootstrap";
import { ButtonWithSpinner } from "components/helpers";
import DisplayItem from "./DisplayItem";

const ProspectForm = ({
  editable,
  prospectFormData,
  saving,
  cancelPath,
  onFieldChange,
  onSubformFieldChange,
  addSubformRow,
  removeSubformRow,
  onSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(prospectFormData.formData);
  };

  const formBody = prospectFormData.formStructure.displayItems.map(
    (displayItem, index) => {
      return (
        <DisplayItem
          key={index}
          editable={editable}
          item={displayItem}
          prospectFormData={prospectFormData}
          onFieldChange={onFieldChange}
          onSubformFieldChange={onSubformFieldChange}
          addSubformRow={addSubformRow}
          removeSubformRow={removeSubformRow}
        />
      );
    }
  );

  if (editable === true) {
    return (
      <Form
        noValidate
        className="mt-4"
        autoComplete="off"
        onSubmit={(e) => {
          handleFormSubmit(e);
        }}
      >
        {formBody}
        <Card bg="light" className="mb-4">
          <Card.Body>
            <a href={cancelPath} className="btn btn-link">
              Cancel
            </a>
            <ButtonWithSpinner
              type="submit"
              saving={saving}
              className="float-end"
            />
          </Card.Body>
        </Card>
      </Form>
    );
  } else {
    return <>{formBody}</>;
  }
};

export default ProspectForm;
