import { loadDisplayItems } from ".";

class SectionDisplay {
  constructor(json, parentStructure) {
    this.type = json.type;
    this.title = json.title;
    this.children = loadDisplayItems(json.children, parentStructure);
  }

  visible(prospectFormData) {
    return this.children.some((item) => {
      // We don't currently support using sections in subforms
      return item.visible(prospectFormData, undefined);
    });
  }
}

export default SectionDisplay;
