export const ActionTypes = {
  HIDE_CONFIRMATION_MODAL: "HIDE_CONFIRMATION_MODAL",
  UPDATE_FORM_VALUE: "UPDATE_FORM_VALUE",
  UPDATE_SUBFORM_VALUE: "UPDATE_SUBFORM_VALUE",
  ADD_SUBFORM_ROW: "ADD_SUBFORM_ROW",
  REMOVE_SUBFORM_ROW: "REMOVE_SUBFORM_ROW",
  CONFIRM_SUBFORM_ROW_REMOVAL: "CONFIRM_SUBFORM_ROW_REMOVAL",
  NEW_COMPLETION_LOG_SUBMIT: "NEW_COMPLETION_LOG_SUBMIT",
  NEW_COMPLETION_LOG_SUBMIT_SUCCESS: "NEW_COMPLETION_LOG_SUBMIT_SUCCESS",
  NEW_COMPLETION_LOG_SUBMIT_FAILURE: "NEW_COMPLETION_LOG_SUBMIT_FAILURE",
  NEW_COMPLETION_LOG_SUBMIT_ERROR: "NEW_COMPLETION_LOG_SUBMIT_ERROR",
  EDIT_COMPLETION_LOG_SUBMIT: "EDIT_COMPLETION_LOG_SUBMIT",
  EDIT_COMPLETION_LOG_SUBMIT_SUCCESS: "EDIT_COMPLETION_LOG_SUBMIT_SUCCESS",
  EDIT_COMPLETION_LOG_SUBMIT_FAILURE: "EDIT_COMPLETION_LOG_SUBMIT_FAILURE",
  EDIT_COMPLETION_LOG_SUBMIT_ERROR: "EDIT_COMPLETION_LOG_SUBMIT_ERROR",
};

const hideConfirmationModal = () => ({
  type: ActionTypes.HIDE_CONFIRMATION_MODAL,
});

const updateFormValue = (fieldIdentifier, value) => ({
  type: ActionTypes.UPDATE_FORM_VALUE,
  fieldIdentifier,
  value,
});

const updateSubformValue = (
  subformIdentifier,
  rowNum,
  fieldIdentifier,
  value
) => ({
  type: ActionTypes.UPDATE_SUBFORM_VALUE,
  subformIdentifier,
  rowNum,
  fieldIdentifier,
  value,
});

const addSubformRow = (subformIdentifier) => ({
  type: ActionTypes.ADD_SUBFORM_ROW,
  subformIdentifier,
});

const removeSubformRow = (subformIdentifier, rowNum) => ({
  type: ActionTypes.REMOVE_SUBFORM_ROW,
  subformIdentifier,
  rowNum,
});

const confirmSubformRowRemoval = (subformIdentifier, rowNum) => ({
  type: ActionTypes.CONFIRM_SUBFORM_ROW_REMOVAL,
  subformIdentifier,
  rowNum,
});

const newCompletionLogSubmit = (
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData
) => ({
  type: ActionTypes.NEW_COMPLETION_LOG_SUBMIT,
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData,
});

const newCompletionLogSubmitSuccess = (responseData) => ({
  type: ActionTypes.NEW_COMPLETION_LOG_SUBMIT_SUCCESS,
  responseData,
});

const newCompletionLogSubmitFailure = (responseData) => ({
  type: ActionTypes.NEW_COMPLETION_LOG_SUBMIT_FAILURE,
  responseData,
});

const newCompletionLogSubmitError = (responseData) => ({
  type: ActionTypes.NEW_COMPLETION_LOG_SUBMIT_ERROR,
  responseData,
});

const editCompletionLogSubmit = (
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData
) => ({
  type: ActionTypes.EDIT_COMPLETION_LOG_SUBMIT,
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData,
});

const editCompletionLogSubmitSuccess = (responseData) => ({
  type: ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_SUCCESS,
  responseData,
});

const editCompletionLogSubmitFailure = (responseData) => ({
  type: ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_FAILURE,
  responseData,
});

const editCompletionLogSubmitError = (responseData) => ({
  type: ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_ERROR,
  responseData,
});

export const actions = {
  hideConfirmationModal,
  updateFormValue,
  updateSubformValue,
  addSubformRow,
  removeSubformRow,
  confirmSubformRowRemoval,
  newCompletionLogSubmit,
  newCompletionLogSubmitSuccess,
  newCompletionLogSubmitFailure,
  newCompletionLogSubmitError,
  editCompletionLogSubmit,
  editCompletionLogSubmitSuccess,
  editCompletionLogSubmitFailure,
  editCompletionLogSubmitError,
};
