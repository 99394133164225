export const questionnaireCompletionLogLookups = [
  {
    identifier: "qst_comp_method",
    type: "enum",
    mapping: [
      { label: "Email", value: 0 },
      { label: "Post", value: 2 },
      { label: "Telephone", value: 3 },
      { label: "SMS", value: 1 },
      { label: "In Person", value: 4 },
    ],
  },
  {
    identifier: "yesno",
    type: "enum",
    mapping: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
  },
];

export const questionnaireCompletionLogStructure = {
  name: "Questionnaire management log",
  modelName: "questionnaire_completion_logs",
  storageName: "questionnaire_completion_logs",
  lookups: questionnaireCompletionLogLookups,
  fields: [
    {
      identifier: "qst_sent_yn",
      type: "enum",
      lookup: "yesno",
      label: "Questionnaire sent?",
    },
    {
      identifier: "no_qst_sent_rsn",
      type: "string",
      label: "Reason questionnaire not sent",
      dependencies: [{ field: "qst_sent_yn", comparison: "==", value: "No" }],
    },
    {
      identifier: "qst_sent_dt",
      type: "date",
      label: "Date of initial sending",
      dependencies: [{ field: "qst_sent_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "qst_sent_meth",
      type: "enum",
      lookup: "qst_comp_method",
      label: "Method of initial sending",
      dependencies: [{ field: "qst_sent_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "reminder_sent",
      type: "boolean",
      label: "Reminder sent?",
      dependencies: [{ field: "qst_sent_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "qst_comp_yn",
      type: "enum",
      lookup: "yesno",
      label: "Was the questionnaire completed?",
      dependencies: [{ field: "qst_sent_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "no_qst_comp_rsn",
      type: "string",
      label: "Reason questionnaire not completed",
      dependencies: [{ field: "qst_comp_yn", comparison: "==", value: "No" }],
    },
    {
      identifier: "qst_comp_dt",
      type: "date",
      label: "Date completed",
      dependencies: [{ field: "no_comp_dt", comparison: "==", value: false }],
    },
    {
      identifier: "no_comp_dt",
      type: "boolean",
      label: "Completion date not provided",
      dependencies: [{ field: "qst_comp_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "qst_comp_meth",
      type: "enum",
      lookup: "qst_comp_method",
      label: "Method",
      dependencies: [{ field: "qst_comp_yn", comparison: "==", value: "Yes" }],
    },
    {
      identifier: "qst_rec_dt",
      type: "date",
      label: "Date received in CTRU",
      dependencies: [
        { field: "qst_comp_meth", comparison: "==", value: "Post" },
      ],
    },
  ],
  subforms: [
    {
      name: "Reminder logs",
      identifier: "reminder_logs",
      modelName: "questionnaire_completion_log_reminder_logs",
      storageName: "questionnaire_completion_log_reminder_logs",
      fields: [
        {
          identifier: "reminder_dt",
          type: "date",
          label: "Date",
        },
        {
          identifier: "reminder_meth",
          type: "enum",
          lookup: "qst_comp_method",
          label: "Method",
        },
        {
          identifier: "reminder_yn",
          type: "enum",
          lookup: "yesno",
          label: "Successful?",
        },
        {
          identifier: "no_reminder_rsn",
          type: "string",
          label: "reason",
          dependencies: [
            { field: "reminder_yn", comparison: "==", value: "No" },
          ],
        },
      ],
      display: [
        {
          type: "field",
          widgetType: "date",
          fieldIdentifier: "reminder_dt",
        },
        {
          type: "field",
          widgetType: "enumRadio",
          fieldIdentifier: "reminder_meth",
        },
        {
          type: "field",
          widgetType: "enumRadio",
          fieldIdentifier: "reminder_yn",
        },
        {
          type: "field",
          widgetType: "text",
          fieldIdentifier: "no_reminder_rsn",
        },
      ],
      dependencies: [{ field: "reminder_sent", comparison: "==", value: true }],
    },
  ],
  display: [
    {
      type: "section",
      title: "",
      children: [
        {
          type: "field",
          widgetType: "enumRadio",
          fieldIdentifier: "qst_sent_yn",
        },
        {
          type: "field",
          widgetType: "text",
          fieldIdentifier: "no_qst_sent_rsn",
        },
        {
          type: "field",
          widgetType: "date",
          fieldIdentifier: "qst_sent_dt",
        },
        {
          type: "field",
          widgetType: "enumRadio",
          inline: false,
          fieldIdentifier: "qst_sent_meth",
        },
        {
          type: "field",
          widgetType: "checkbox",
          fieldIdentifier: "reminder_sent",
        },
        {
          type: "subform",
          title: "Reminders",
          subformIdentifier: "reminder_logs",
        },
      ],
    },
    {
      type: "section",
      title: "",
      children: [
        {
          type: "field",
          widgetType: "enumRadio",
          fieldIdentifier: "qst_comp_yn",
        },
        {
          type: "field",
          widgetType: "text",
          fieldIdentifier: "no_qst_comp_rsn",
        },
        {
          type: "inlineFields",
          label: "Date completed",
          leftField: {
            type: "field",
            widgetType: "date",
            fieldIdentifier: "qst_comp_dt",
          },
          rightField: {
            type: "field",
            widgetType: "checkbox",
            fieldIdentifier: "no_comp_dt",
          },
        },
        {
          type: "field",
          widgetType: "enumRadio",
          inline: false,
          fieldIdentifier: "qst_comp_meth",
        },
        {
          type: "field",
          widgetType: "date",
          fieldIdentifier: "qst_rec_dt",
        },
      ],
    },
  ],
};
