import React from "react";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  questionnaireCompletionLogStructure,
  questionnaireCompletionLogLookups,
} from "services/questionnaire_completion_log_structure";
import { loadFormDataFrom } from "reducers/completionLogFormReducer.reducer";
import ProspectFormData from "services/prospect_form_data";

import { ProspectForm } from "components/prospect_form";

const ViewQuestionnaireCompletionLogPage = ({ completionLogData }) => {
  console.log(completionLogData);

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ProspectForm
        editable={false}
        prospectFormData={
          new ProspectFormData(
            questionnaireCompletionLogStructure,
            questionnaireCompletionLogLookups,
            loadFormDataFrom(completionLogData)
          )
        }
      />
    </Sentry.ErrorBoundary>
  );
};

export default ViewQuestionnaireCompletionLogPage;
