import { postRequest, patchRequest } from "./base_requests";

const blankFieldIfHidden = (field) => {
  if (field.hidden == true) return "";

  if (field.value instanceof Date) {
    return field.value.toLocaleDateString("en-gb");
  } else {
    return field.value;
  }
};

const valuesToSendFor = (formData) => {
  const contactLogAttributes = formData.reminder_logs
    .filter((row) => row.persisted || row.hidden !== true)
    .map((row, index) => {
      return {
        id: row.id,
        _destroy: row.marked_for_destruction || row.hidden ? "1" : "0",
        row_num: row.row_num,
        reminder_dt: blankFieldIfHidden(row.subformData.reminder_dt),
        reminder_meth: blankFieldIfHidden(row.subformData.reminder_meth),
        reminder_yn: blankFieldIfHidden(row.subformData.reminder_yn),
        no_reminder_rsn: blankFieldIfHidden(row.subformData.no_reminder_rsn),
      };
    });

  return {
    qst_sent_yn: blankFieldIfHidden(formData.qst_sent_yn),
    qst_sent_dt: blankFieldIfHidden(formData.qst_sent_dt),
    qst_sent_meth: blankFieldIfHidden(formData.qst_sent_meth),
    no_qst_sent_rsn: blankFieldIfHidden(formData.no_qst_sent_rsn),
    reminder_sent: blankFieldIfHidden(formData.reminder_sent),
    qst_comp_yn: blankFieldIfHidden(formData.qst_comp_yn),
    no_qst_comp_rsn: blankFieldIfHidden(formData.no_qst_comp_rsn),
    qst_rec_dt: blankFieldIfHidden(formData.qst_rec_dt),
    qst_comp_dt: blankFieldIfHidden(formData.qst_comp_dt),
    no_comp_dt: blankFieldIfHidden(formData.no_comp_dt),
    qst_comp_meth: blankFieldIfHidden(formData.qst_comp_meth),
    reminder_logs_attributes: contactLogAttributes,
  };
};

export function createCompletionLog(
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData
) {
  const valuesToSend = valuesToSendFor(formData);

  return postRequest({
    url: `/studies/${studyIdentifier}/individuals/${individualIdentifier}/events/${eventDefinitionIdentifier}/questionnaires`,
    body: {
      identifier: questionnaireIdentifier,
      questionnaire_management_log: {
        ...valuesToSend,
      },
    },
  });
}

export function updateCompletionLog(
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  formData
) {
  const valuesToSend = valuesToSendFor(formData);

  return patchRequest({
    url: `/studies/${studyIdentifier}/individuals/${individualIdentifier}/events/${eventDefinitionIdentifier}/questionnaires/${questionnaireIdentifier}`,
    body: {
      questionnaire_management_log: {
        ...valuesToSend,
      },
    },
  });
}
