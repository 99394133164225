import _ from "lodash";
import { postRequest } from "./base_requests";

export function runReport(studyId, filterValues) {
  // Convert the keys into the snakecase expected by Rails
  const newFilterValues = Object.keys(filterValues).reduce(
    (result, key) => ({
      ...result,
      [_.snakeCase(key)]: filterValues[key],
    }),
    {}
  );

  return postRequest({
    url: `/studies/${studyId}/reports/freeze_and_lock_states/run_report.json`,
    body: {
      filter: newFilterValues,
    },
  });
}
