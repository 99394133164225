import SectionDisplay from "./section_display";
import SubformDisplay from "./subform_display";
import FieldDisplay from "./field_display";
import InlineFieldsDisplay from "./inline_fields_display";

export { SectionDisplay, FieldDisplay };

export const loadDisplayItems = (json, parentStructure) => {
  return json.map((itemJson) => {
    switch (itemJson.type) {
      case "section": {
        return new SectionDisplay(itemJson, parentStructure);
      }
      case "field": {
        return new FieldDisplay(itemJson, parentStructure);
      }
      case "subform": {
        return new SubformDisplay(itemJson, parentStructure);
      }
      case "inlineFields": {
        return new InlineFieldsDisplay(itemJson, parentStructure);
      }
      default: {
        throw `Unknown display type of "${itemJson.type}"`;
      }
    }
  });
};
