import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/management_report";

import { Alert, Card } from "react-bootstrap";
import { FlashMessagePortal } from "components/helpers";
import ResultsTable from "components/management_report_page/ResultsTable";
import ReportForm from "components/management_report_page/ReportForm";
import BulkContactForm from "components/management_report_page/BulkContactForm";

const ManagementReportScreen = ({
  availableQuestionnaires,
  availableSites,
  messages,
  dismissMessage,
}) => {
  return (
    <>
      <FlashMessagePortal messages={messages} dismissMessage={dismissMessage} />
      <h1>Questionnaire Management Report</h1>

      {availableQuestionnaires.length === 0 && (
        <Alert show={true} transition={false} variant="warning">
          There are currently no questionnaires set up for this study. Please
          contact your study manager for further assistance.
        </Alert>
      )}
      {availableSites.length === 0 && (
        <Alert show={true} transition={false} variant="warning">
          You do not currently have permission to produce reports at any sites
          for this study. Please contact your study manager for further
          assistance.
        </Alert>
      )}
      {availableQuestionnaires.length > 0 && availableSites.length > 0 && (
        <>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title style={{ marginBottom: 0 }}>Filter</Card.Title>
            </Card.Header>
            <Card.Body>
              <ReportForm />
            </Card.Body>
          </Card>
          <BulkContactForm />
          <ResultsTable />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.managementReport;

  return {
    availableQuestionnaires: relevantState.availableQuestionnaires,
    availableSites: relevantState.availableSites,
    messages: relevantState.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dismissMessage: actions.dismissFlashMessage,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementReportScreen);
