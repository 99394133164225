import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "studyPermissions",
    "sitePermissions",
    "studyPermissionCheckbox",
    "sitePermissionCheckbox",
    "permissionDivider",
    "reportsAndAuxForms",
  ];
  static values = {
    type: String,
  };

  connect() {
    if (this.typeValue === "") {
      // if we don't already have a role type value, hide all permissions
      this.hidePermissions();
      this.sitesShown = false;
    } else {
      // Otherwise, show the appropriate permissions
      this.updateStudyPermissions();
      this.sitesShown = true;
    }
  }

  changeType(event) {
    this.typeValue = event.target.value;
    this.updateStudyPermissions();

    // If all permissions were hidden, show the site permissions
    if (!this.sitesShown) {
      this.showSitePermissions();
      this.sitesShown = true;
    }
  }

  updateStudyPermissions() {
    if (this.typeValue === "site") {
      this.studyPermissionsTarget.classList.add("d-none");
      let checkboxes = this.studyPermissionCheckboxTargets;
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute("disabled", "");
      });
    } else if (this.typeValue === "central") {
      this.studyPermissionsTarget.classList.remove("d-none");
      let checkboxes = this.studyPermissionCheckboxTargets;
      checkboxes.forEach((checkbox) => {
        checkbox.removeAttribute("disabled");
      });
    }
  }

  hidePermissions() {
    this.studyPermissionsTarget.classList.add("d-none");
    this.sitePermissionsTarget.classList.add("d-none");
    this.permissionDividerTargets.forEach((divider) => {
      divider.classList.add("d-none");
    });
    this.reportsAndAuxFormsTarget.classList.add("d-none");

    let studyCheckboxes = this.studyPermissionCheckboxTargets;
    let siteCheckboxes = this.sitePermissionCheckboxTargets;

    studyCheckboxes.forEach((checkbox) => {
      checkbox.setAttribute("disabled", "");
    });

    siteCheckboxes.forEach((checkbox) => {
      checkbox.setAttribute("disabled", "");
    });
  }

  showSitePermissions() {
    this.sitePermissionsTarget.classList.remove("d-none");
    this.permissionDividerTargets.forEach((divider) => {
      divider.classList.remove("d-none");
    });
    this.reportsAndAuxFormsTarget.classList.remove("d-none");

    let siteCheckboxes = this.sitePermissionCheckboxTargets;
    siteCheckboxes.forEach((checkbox) => {
      checkbox.removeAttribute("disabled");
    });
  }
}
