import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";

const CSVDownloadButton = ({
  headers,
  data,
  filename,
  addTimeStamp,
  active,
  children,
}) => {
  let fullFilename = filename;
  if (addTimeStamp === true) {
    const timestamp = moment().format("YYYYMMDD_HHmm");
    fullFilename = `${filename}_${timestamp}.csv`;
  }

  return (
    <CSVLink
      headers={headers}
      data={data}
      filename={fullFilename}
      className={`btn btn-outline-primary ${active > 0 ? "" : "disabled"}`}
      target="_blank"
    >
      {children}
    </CSVLink>
  );
};

export default CSVDownloadButton;
