import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import { Select } from "components/helpers";

const SelectField = ({
  fieldName,
  label,
  value,
  options,
  onFieldChange,
  errors,
  placeholder,
  isMulti,
  isClearable,
  disabled,
  labelClassName,
  inputClassName,
  inline = false,
  selectAllHandler = undefined,
}) => {
  const handleOnChange = (selection) => {
    if (isMulti) {
      const selectedOptions = selection
        ? selection.map((option) => option.value)
        : [];
      return onFieldChange(fieldName, selectedOptions);
    } else if (selection != null) {
      return onFieldChange(fieldName, selection.value);
    } else {
      return onFieldChange(fieldName, null);
    }
  };

  // If it's a multiple select then the value is expected to be an array or null
  // If it's a single select then the value should be a string or null
  let processedValue;
  if (isMulti) {
    processedValue =
      options.filter((option) => value.includes(option.value)) || null;
  } else {
    processedValue =
      options.filter((option) => option.value === value)[0] || null;
  }

  const controlId = `select-field-${fieldName}`;

  let classes = ["react-select", controlId];
  if (errors.length > 0) {
    classes.push("is-invalid");
  }
  if (inputClassName) {
    classes.push(inputClassName);
  }
  const className = classes.join(" ");

  const FormControl = (
    <Select
      isMulti={isMulti}
      isClearable={isClearable}
      options={options}
      value={processedValue}
      onChange={handleOnChange}
      className={className}
      classNamePrefix={`react-select`}
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );

  const ErrorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (inline) {
    return (
      <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm={4} className={labelClassName}>
          {label}
        </Form.Label>
        <Col sm={8}>
          {FormControl}
          {ErrorFeedback}
        </Col>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className="select-group mb-3" controlId={controlId}>
        <Form.Label className={labelClassName}>{label}</Form.Label>
        {selectAllHandler && (
          <Button
            className="float-end"
            variant="link"
            size="sm"
            onClick={selectAllHandler}
            disabled={disabled}
          >
            {" "}
            Select all{" "}
          </Button>
        )}
        {FormControl}
        {ErrorFeedback}
      </Form.Group>
    );
  }
};

export default SelectField;
