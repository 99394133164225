import React from "react";

import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, modalBody, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalBody}</Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button variant="outline-dark" className="float-start" onClick={onHide}>
          No
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
