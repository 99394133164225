import { processRequest, dispatchWithTimeout } from "services/base_requests";

import { actions, ActionTypes } from "actions/freeze_and_lock_states_report";
import { runReport } from "services/freeze_and_lock_states_report_requests";

const FreezeAndLockStatesReportMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-danger");

    switch (action.type) {
      case ActionTypes.RUN_REPORT: {
        const request = runReport(action.studyId, action.filterValues);
        const success = successHandlerFor(actions.runReportSuccess);
        const failure = failureHandlerFor(actions.runReportFailure);
        const error = failureHandlerFor(
          actions.runReportError,
          "Unable to run the report, please try again and contact support if the problem persists"
        );

        processRequest(request, success, failure, error);
        break;
      }
    }

    return next(action);
  };

export default FreezeAndLockStatesReportMiddleware;
